try {
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
} catch (e) {}

// Collapse Navbar
const navbarCollapse = () => {
    let $mainNav = $("#mainNav");
    if ($mainNav.offset().top > 150) {
        $mainNav.addClass("navbar-shrink");
    } else {
        $mainNav.removeClass("navbar-shrink");
    }
};

// Collapse the navbar when page is scrolled
$(window).scroll(navbarCollapse);

$(function() {

    // Collapse now if page is not at top
    navbarCollapse();

    // active class names
    let path = window.location.pathname.split("/")[1];
    $('.navbar-nav > li > a[href="'+path+'"]').parent()
        .addClass('active');
    $('.dropdown-menu > a[href="'+path+'"]')
        .addClass('active')
        .parents('.nav-item').addClass('active');
    $('.product-list a[href="'+path+'"]')
        .parents('li').addClass('active');

});

// Inquiry Validation
function MM_validateForm() { //v4.0
if (document.getElementById){
    var i,p,q,nm,test,num,min,max,errors='',args=MM_validateForm.arguments;
    for (i=0; i<(args.length-2); i+=3) { test=args[i+2]; val=document.getElementById(args[i]);
        if (val) { nm=val.name; if ((val=val.value)!="") {
            if (test.indexOf('isEmail')!=-1) { p=val.indexOf('@');
                if (p<1 || p==(val.length-1)) errors+='- '+nm+' must contain an e-mail address.\n';
            } else if (test!='R') { num = parseFloat(val);
                if (isNaN(val)) errors+='- '+nm+' must contain a number.\n';
                if (test.indexOf('inRange') != -1) { p=test.indexOf(':');
                    min=test.substring(8,p); max=test.substring(p+1);
                    if (num<min || max<num) errors+='- '+nm+' must contain a number between '+min+' and '+max+'.\n';
                } } } else if (test.charAt(0) == 'R') errors += '- '+nm+' is required.\n'; }
    } if (errors) alert('The following error(s) occurred:\n'+errors);
    document.MM_returnValue = (errors == '');
} }

// Map Script
var map;

function initialize() {
    var myLatlng = new google.maps.LatLng(21.955594,70.794233);

    var myOptions = {
        zoom: 18,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    var map = new google.maps.Map(document.getElementById('map'),
        myOptions);

    var contentString = '<div id="map-content">'+
        '<p class="txt-map">Pragati Machine Tools</p>'+
        '<p class="txt-map-content">29, Pole Cement Factory Compound, ' +
        'Jetpur Road, Gondal - 360311, <br> '+
        'Gujarat INDIA.</p> '+
        '</div>';

    var infowindow = new google.maps.InfoWindow({
        content: contentString
    });

    var marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        title:"Pragati Machine Tools"

    });

    google.maps.event.addListener(marker, 'click', function() {
        infowindow.open(map,marker);
    });

}

if($('#map').length > 0) {
    google.maps.event.addDomListener(window, 'load', initialize);
}
